<script setup lang="ts">
interface Props {
  label: string
}

defineProps<Props>()

const isOpen = defineModel<boolean>({ required: true })

const { icons } = useDesign()

defineOptions({
  name: 'PageFooterColumnHeading',
})
</script>

<template>
  <button
    class="mb-2 flex w-full flex-row items-center justify-between p-2 md:hidden"
    @click="isOpen = !isOpen"
  >
    <span class="font-bold text-[#C1C0D5]" v-text="label" />

    <Icon
      :name="icons.CHEVRON_DOWN"
      class="size-6 text-[#C1C0D5] transition-transform duration-300"
      :class="{ 'rotate-180': isOpen }"
    />
  </button>

  <span class="mb-2 hidden font-bold text-[#C1C0D5] md:block" v-text="label" />
</template>
