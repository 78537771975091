<script setup lang="ts">
import type { CmsMenu } from '#gql/default'

interface Props {
  children: CmsMenu[]
}

const { children } = defineProps<Props>()

const isOpen = defineModel<boolean>({ required: true })

defineOptions({
  name: 'PageFooterColumnLinks',
})
</script>

<template>
  <Transition name="accordion" class="block md:hidden">
    <div
      class="flex flex-col overflow-hidden px-2 py-1 md:px-0 md:py-0"
      v-if="isOpen"
    >
      <PageFooterColumnLink
        :link
        v-for="(link, index) in children"
        :key="`sub-menu-link-${index}`"
      />
    </div>
  </Transition>

  <PageFooterColumnLink
    class="hidden md:block"
    :link
    v-for="(link, index) in children"
    :key="`sub-menu-link-${index}`"
  />
</template>

<style scoped>
.accordion-enter-active,
.accordion-leave-active {
  transition:
    max-height 0.3s ease,
    opacity 0.3s ease;
  max-height: 300px;
  opacity: 1;
}

.accordion-enter-from,
.accordion-leave-to {
  max-height: 0;
  opacity: 0;
}
</style>
