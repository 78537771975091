<script setup lang="ts">
const { config } = useCms()

const hasColumns = computed(() => {
  return (
    config.value?.footerMenu &&
    config.value?.footerMenu.length > 0 &&
    config.value?.footerMenu.some(({ children }) => children.length > 0)
  )
})

defineOptions({
  name: 'PageFooter',
})
</script>

<template>
  <PageContainer
    id="footer"
    as="footer"
    :default-theme="false"
    :default-vertical-spacing="false"
    :grow="false"
    class="bg-footer bleed-footer bleed-bg skin:bleed-none"
  >
    <nav
      v-if="hasColumns"
      class="grid w-full grid-cols-1 py-4 md:grid-cols-2 md:gap-8 lg:grid-cols-4"
    >
      <PageFooterColumn
        v-for="({ children, label }, index) in config?.footerMenu"
        :key="index"
        :label
        :children
      />

      <div
        class="col-span-1 mt-8 flex w-full flex-row justify-between md:col-span-2 lg:col-span-4"
      >
        <SvgPoweredByEventim class="fill-on-footer h-[36px] w-[67px]" />

        <div class="flex flex-row gap-2 md:gap-0">
          <SocialLink
            v-for="socialLink in config?.socialLinks"
            :key="socialLink"
            :link="socialLink"
            class="text-on-footer dark:text-primary transition-opacity duration-150 hover:opacity-80"
          />
        </div>
      </div>
    </nav>
  </PageContainer>
</template>
