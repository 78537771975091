<script setup lang="ts">
import type { CmsMenu } from '#gql/default'
import { LINK_TARGET } from '@base/constants/link'

interface Props {
  link: CmsMenu
}

const { link } = defineProps<Props>()

const { toggleModal } = useConsentManager()

function isCookieConsentLink(url: string) {
  return url === '/cookies'
}

defineOptions({
  name: 'PageFooterColumnLink',
})
</script>

<template>
  <button
    v-if="isCookieConsentLink(link.url)"
    type="button"
    class="text-on-footer hover:text-secondary text-start"
    @click="toggleModal({ settings: true, initial: false })"
    v-text="link.label"
  />

  <NuxtLink
    v-else
    class="text-on-footer hover:text-secondary inline-block"
    :to="link.url"
    :target="
      link.url.startsWith('http') || link.url.startsWith('www')
        ? LINK_TARGET.BLANK
        : LINK_TARGET.SELF
    "
  >
    {{ link.label }}
  </NuxtLink>
</template>
