<script setup lang="ts">
import type { CmsMenu } from '#gql/default'

interface Props {
  label: string
  children: CmsMenu[]
}

defineProps<Props>()

const isOpen = ref(false)

defineOptions({
  name: 'PageFooterColumn',
})
</script>

<template>
  <div class="flex flex-col">
    <PageFooterColumnHeading :label v-model="isOpen" />
    <PageFooterColumnLinks :children v-model="isOpen" />
  </div>
</template>
